input[data-v-7200bb61] {
  border: 1px solid var(--bg-secondary-alt);
  caret-color: var(--heading);
}
input[data-v-7200bb61]:hover {
  border: 1px solid var(--bg-tertiary);
}
input[data-v-7200bb61]:focus {
  border: 1px solid var(--link);
}
