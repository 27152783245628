.message[data-v-d5063a44]:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.message:hover .timestamp[data-v-d5063a44],
.message:hover .toolbar[data-v-d5063a44] {
  opacity: 1;
}
.timestamp[data-v-d5063a44],
.toolbar[data-v-d5063a44] {
  opacity: 0;
}
