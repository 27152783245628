input ~ .dot[data-v-33b6f2e7] {
  transition: 0.3s ease-in-out !important;
}

/* compensation for save changes rerendering channel-settings-perms */
input[value='on'] ~ .dot[data-v-33b6f2e7] {
  transform: translateX(100%);
  background-color: var(--success);
}
input[value='off'] ~ .dot[data-v-33b6f2e7] {
  transform: translateX(0%);
  background-color: var(--danger);
}
input[value='n/a'] ~ .dot[data-v-33b6f2e7] {
  background-color: #ccc;
  transform: translateX(50%);
}
