img[data-v-9e98be31] {
  border-radius: inherit;
}
.selected[data-v-9e98be31] {
  transition: width 0.3s ease;
}
.active .selected[data-v-9e98be31] {
  height: 40px;
  margin-top: 4px;
}
.wrapper:not(.active):hover .selected[data-v-9e98be31] {
  display: block;
  height: 20px;
  margin-top: 14px;
}
