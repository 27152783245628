@import './styles/output.css';
@import '~@fortawesome/fontawesome-svg-core/styles.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--bg-secondary);
  border-radius: 10px;
}

body {
  text-rendering: optimizeLegibility;
  overflow-y: hidden;
}

/* overrides */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.react-contextmenu-wrapper {
  display: inline;
}

nav[role='menu'] {
  z-index: 10;
}

/* fix div[content-editable] placeholder */
[contenteditable='true']:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  color: var(--muted);
}
